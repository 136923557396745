import { render, staticRenderFns } from "./insert_rota.vue?vue&type=template&id=47d55965&scoped=true"
import script from "./insert_rota.vue?vue&type=script&lang=js"
export * from "./insert_rota.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47d55965",
  null
  
)

export default component.exports